
/*--------------------------------------------------------------
new. next-page-promo
--------------------------------------------------------------*/
.next-page-promo {
	text-align: center;

	.promo-message {
		margin-top: 70px;
		padding: 0 200px 90px;

		@include media-query(991px) {
			margin-top: 50px;
			padding: 0 100px 90px;
		}

		@include media-query(767px) {
			padding: 0 0 80px;
		}

    @include media-query(575px) {
      padding: 0 0 60px;
    }

		h3 {
			font-size: 30px;
			font-size: calc-rem-value(30);
			margin: 0 0 0.8em;
		}

		p {
			margin-bottom: 1.8em;
		}
	}
}