.page-body {
  & > .row {
    display: flex;
    justify-content: center;
  }

  &.margin-bottom {
    margin-bottom: 20px;
  }

  &.margin-top {
    margin-top: 80px;
  }

  & h2 {
    font-family: "Jost", sans-serif;
    font-weight: 600;
    color: $dark-gray;
    margin-bottom: 20px;
  }

  & p {
    font-size: 18px;
    line-height: 1.8em;
    color: $text-color;
  }

  & a {
    color: $theme-primary-color-s2;
    text-decoration: underline;
  }
}