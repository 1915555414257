/*--------------------------------------------------------------
13. coming-soon-section
--------------------------------------------------------------*/

.coming-soon-section {
    max-width: 1000px;
    background: rgba(255, 255, 255, .85);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, .5);
    margin: 0 auto;
    padding: 50px 40px 20px;

    @media(max-width:1199px) {
        max-width: 94%;
        padding: 40px 20px 20px;
    }

    @media(max-width:991px) {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, .5);
        padding: 20px 10px;
        max-width: 98%;
    }

    .wpo-wedding-date {
        padding: 30px 0 60px;

        @include media-query(767px) {
            padding: 0px 0 15px;
        }
    }


    .coming-soon-text {
        text-align: center;

        h2 {
            font-size: 60px;
            margin-bottom: 30px;

            @include media-query(1199px) {
                font-size: 50px;
            }

            @include media-query(767px) {
                font-size: 30px;
            }
        }
        p{
            margin-bottom: 0;
        }
    }

    .wpo-coming-contact {
        .form-control {
            width: 100%;
            height: 55px;
            background: none;
            border: 1px solid #ccc;
            margin-bottom: 10px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .error{
            margin-bottom: 10px;
        }

        button {
            height: 55px;
            background: $theme-primary-color-s2;
            border: none;
            color: $white;
            width: 100%;
            font-size: 16px;
        }
    }
}