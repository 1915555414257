/*======================================
 10. Home-style-7
 =======================================*/

/*10.1 wpo-couple-section-s4*/

.wpo-couple-section-s4 {
	padding-top: 0;

	.couple-area {
		margin-top: -350px;

		@media(max-width:1199px) {
			margin-top: -250px;
		}

		@media(max-width:991px) {
			margin-top: -120px;
		}

		.couple-item {
			.couple-img {
				padding: 20px;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
				max-width: 100%;
				background: $white;

				&::before,
				&::after {
					display: none;
				}

				img {
					@media(max-width:991px) {
						width: 100%;
					}
				}

				@media(max-width:991px) {
					margin-bottom: 20px;
				}
			}

			.couple-text {
				h3 {
					@media(max-width:1399px) {
						font-size: 32px;
						margin-bottom: 15px;
					}

					@media(max-width:991px) {
						font-size: 23px;
						margin-bottom: 10px;
					}
				}
			}
		}

		.middle-couple-shape {
			padding-top: 50px;

			.middle-img {
				padding: 20px;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
				max-width: 370px;
				margin: 0 auto;
				margin-bottom: 40px;
				background: $white;

				@media(max-width:1399px) {
					max-width: 320px;
				}

				@media(max-width:1199px) {
					max-width: 250px;
				}

				@media(max-width:991px) {
					max-width: 200px;
				}

				@media(max-width:991px) {
					max-width: 320px;
				}
			}
		}
	}
}


/*10.2 wpo-story-section-s4*/

.wpo-story-section-s4 {
	.wpo-story-wrap {
		.wpo-story-item {
			max-width: 1235px;
			margin: 0 auto;
			display: flex;
			padding-bottom: 120px;

			@media(max-width:991px) {
				flex-wrap: wrap;
				padding-bottom: 50px;
			}


			.wpo-story-content-wrap {
				padding: 0 15px;
				flex-basis: 50%;

				@media(max-width:991px) {
					flex-basis: 100%;
					padding: 0;
				}

				.wpo-story-content-img {
					position: relative;
					z-index: 1;

					&:before {
						position: absolute;
						left: -80px;
						bottom: -60px;
						width: 240px;
						height: 226px;
						content: "";
						background: #F2EEEC;
						z-index: -1;

						@media(max-width:1199px) {
							width: 180px;
							left: -40px;
						}
						@media(max-width:991px) {
							display: none;
						}
					}

					img{
						@media(max-width:991px) {
							width: 100%;
						}
					}
				}

				.wpo-story-content {
					padding: 40px;
					background: none;
					border: 1px solid $theme-primary-color-s2;
					z-index: 11;
					margin-top: 30px;
					text-align: center;
					position: relative;

					@media(max-width:1399px) {
						padding: 25px;
					}
					@media(max-width:1199px) {
						padding: 15px 25px;
					}
					@media(max-width:991px) {
						margin-bottom: 30px;
					}


					@media(max-width:575px) {
						padding: 20px;
					}

					h2 {
						font-weight: 400;
						font-size: 32px;
						line-height: 46px;
						margin-bottom: 30px;

						@media(max-width:1199px) {
							margin-bottom: 20px;
							font-size: 25px;
							line-height: 36px;
						}

						@media(max-width:575px) {
							font-size: 20px;
							margin-bottom: 10px;
						}
					}

					span {
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 29px;
						color: $theme-primary-color-s2;
						margin-bottom: 20px;
						display: block;

						@media(max-width:1199px) {
							margin-bottom: 10px;
						}

						@media(max-width:575px) {
							font-size: 18px;
						}
					}

					p {
						font-weight: 400;
						font-size: 20px;
						line-height: 29px;
						color: $text-color;
						margin-bottom: 0;

						@media(max-width:1399px) {
							font-size: 18px;
						}

						@media(max-width:575px) {
							font-size: 17px;
						}
					}
				}

			}

			.wpo-story-img-wrap {
				flex-basis: 50%;
				padding: 0 15px;
				position: relative;

				@media(max-width:991px) {
					flex-basis: 100%;
					padding: 0;
				}

				&:before {
					position: absolute;
					right: -80px;
					bottom: -60px;
					width: 240px;
					height: 456px;
					content: "";
					background: #F2EEEC;
					z-index: -1;

					@media(max-width:1199px) {
						width: 180px;
						right: -40px;
					}

					@media(max-width:991px) {
						display: none;
					}
				}

				img{
					@media(max-width:991px) {
						width: 100%;
					}
				}

			}

			&:nth-child(even) {
				.wpo-story-content-wrap {
					order: 2;
					-webkit-order: 2;
					-moz-order: 2;


					@media(max-width:991px) {
						order: unset;
						-webkit-order: unset;
						-moz-order: unset;
					}

					.wpo-story-content-img {

						&:before {
							left: auto;
							right: -80px;
							@media(max-width:1199px) {
								right: -40px;
							}
						}
	
					}
				}

				.wpo-story-img-wrap{
					order: 1;
					-webkit-order: 1;
					-moz-order: 1;

					@media(max-width:991px) {
						order: unset;
						-webkit-order: unset;
						-moz-order: unset;
					}

					&:before {
						right: auto;
						left: -80px;

						@media(max-width:1199px) {
							width: 180px;
							left: -40px;
						}
					}

					@media(max-width:991px) {
						margin-right: 0;
					}
				}
			}

			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}

/*10.4 wpo-contact-section-s5 */

.wpo-contact-section-s5 {
	background: url(../../images/contact/bg.jpg) no-repeat center center;
	background-size: cover;

	.wpo-contact-section-wrapper{
		margin-left: auto;
		max-width: 530px;
	}
}