.faq-item {
  & span {
    font-size: 1.2rem;
  }

  & p {
    @media(max-width:480px){
      font-size: 1rem;
    }

    // font-size: 1.2rem;
  }
}