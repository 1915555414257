.faq-form {
  padding: 30px 10px;
  background: $white;
  box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
  position: relative;
  z-index: 99;

  @media(max-width:767px) {
    padding: 20px 5px;
  }

  .form-field{
		margin-bottom: 25px;

    @media(max-width:767px) {
      margin-bottom: 20px;
    }
	}

  form input,
	form select,
	form textarea {
		background: transparent;
		width: 100%;
		height: 50px;
		border: 1px solid transparent;
		border-radius: 0px;
		box-shadow: none !important;
		padding-left: 25px;
		border: 1px solid #ebebeb;

		@include media-query(991px) {
			height: 45px;
		}

		&:focus {
			border-color: $theme-primary-color;
			background: transparent;
			box-shadow: none;
			outline: none;
		}
	}

	form textarea {
		height: 180px;
		padding-top: 15px;
	}

	form {
		margin: 0 -15px;
		overflow: hidden;

		@include placeholder-style(#9d9c9c, 15px, normal);

		select {
			display: inline-block;
			color: #a9a9a9;
			cursor: pointer;
			opacity: 1;
			padding: 6px 25px;
			font-size: 15px;
			font-size: calc-rem-value(15);
			-webkit-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance: none;
			-moz-appearance: none;
			background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
			position: relative;

			&:focus {
				background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
			}
		}

		.submit-area {
			text-align: center;
			width: 100%;
			margin-bottom: 10px;
			margin-left: 0;

			@include media-query(767px) {
				margin-bottom: 0;
			}

			.theme-btn {
				border-radius: 0px;
				font-family: $base-font;
				font-size: 16px;
        min-width: 144.77px;

        @include media-query(767px) {
          margin-bottom: 90.77;
        }

				&:after {
					border-radius: 0px;
				}

        &:disabled {
          background-color: $theme-primary-color-s3;
        }
			}
		}
	}

	form>div {
		margin: 0 15px 25px;

		@include media-query(600px) {
			width: calc(100% - 25px);
			float: none;
		}
	}

	form .fullwidth {
		width: calc(100% - 25px);
		float: none;
		clear: both;
	}
}